var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"backdrop"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex-col"},[_c('div',{staticClass:"mb-3 header"},[_c('h1',{staticClass:"text-center"},[_vm._v("Welcome to the AI Video Creator")]),_c('p',{staticClass:"text-center",style:({ fontSize: '20px' })},[_vm._v(" Create stunning video content for your campaign in minutes ")])]),_c('div',{staticClass:"flex-card"},[_c('div',{style:({ backgroundColor: 'yellow' })},[_vm._m(0),_c('div',{staticClass:"overlay"},[_c('div',{staticStyle:{"width":"100%"}},[_c('h3',[_vm._v("AI Video Creator")]),_c('button',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center","gap":"1rem"},style:({
                  textDecoration: 'none',
                  cursor: _vm.isDisabled ? 'not-allowed' : 'pointer',
                }),on:{"click":function($event){return _vm.showPopup('https://www.youtube.com/embed/g94mFXMp1gU')}}},[_c('span',[_vm._v(" Coming Soon")]),_c('svg',{attrs:{"width":"26","height":"26","viewBox":"0 0 15 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"x":"0.441176","y":"0.941176","width":"14.1176","height":"14.1176","rx":"2.20997","stroke":"currentColor","stroke-width":"0.882353"}}),_c('path',{attrs:{"d":"M4.0909 5.63622C4.0909 4.83048 4.96449 4.32898 5.66064 4.73446L10.3652 7.47941C11.0556 7.88176 11.0556 8.87954 10.3652 9.28241L5.66064 12.0263C4.96449 12.4323 4.0909 11.9308 4.0909 11.1256V5.63622ZM9.83912 8.38065L5.13461 5.63622V11.1256L9.83912 8.38065Z","fill":"currentColor"}})])])])])]),_c('div',{staticClass:"py-3"},[_c('div',{staticClass:"video-editor"},[_c('img',{attrs:{"src":require('~/assets/images/aiAutomation.png')}}),_c('div',{style:({ alignSelf: 'center' })},[_c('h2',{staticClass:"capitalize"},[_vm._v("Create cool campaign videos using our video composer")]),_c('p',{style:({ maxWidth: '90%', marginTop: '20px', fontSize: '18px' })},[_vm._v(" Effortlessly design professional-quality campaign videos with our intuitive, feature-rich video composer. Boost engagement, enhance your brand, and captivate your audience today! ")]),_c('div',{staticStyle:{"display":"flex","gap":"1.5rem"}},[_c('div',[(!_vm.isDisabled)?_c('router-link',{staticClass:"get-started d-inline-block bg-primary text-white mt-3 py-3 px-5",style:({ textDecoration: 'none' }),attrs:{"to":{ name: 'recent-videos' }}},[_vm._v(" Get Started ")]):_c('button',{staticClass:"get-started d-inline-block bg-primary text-white mt-3 py-3 px-5",style:({
                      textDecoration: 'none',
                      cursor: _vm.isDisabled ? 'not-allowed' : 'pointer',
                    }),attrs:{"disabled":""}},[_vm._v(" "+_vm._s(_vm.timeRemaining.total <= 0 ? 'Launched!' : ' COMING SOON')+" ")])],1),_c('button',{staticClass:"get-started d-inline-block bg-success text-white mt-3 py-3 px-5",style:({ textDecoration: 'none', border: 'none', cursor: _vm.isDisabled ? 'not-allowed' : 'pointer' }),on:{"click":function($event){return _vm.showPopup('https://www.youtube.com/embed/U0HNdsxC8YU')}}},[_vm._v(" WATCH DEMO ")]),(_vm.isPopupVisible)?_c('DemoVideo',{attrs:{"videoUrl":_vm.videoUrl,"isVisible":_vm.isPopupVisible},on:{"close":_vm.closePopup}}):_vm._e()],1),_c('div',{staticStyle:{"background":"#ffaa00","max-width":"87%","margin-top":"1.5rem"}},[_c('div',{staticClass:"text-center text-white fw-bold p-1"},[_c('h4',[_vm._v("Launching 30th January, 2025")]),_c('p',[_vm._v(" Launch in: "+_vm._s(_vm.timeRemaining.days)+" Days, "+_vm._s(_vm.timeRemaining.hours)+" Hours, "+_vm._s(_vm.timeRemaining.minutes)+" Minutes, "+_vm._s(_vm.timeRemaining.seconds)+" Seconds ")])])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('video',[_c('source',{attrs:{"src":"/ai-video.mp4","type":"video/mp4"}}),_vm._v(" Your browser does not support the video tag. ")])
}]

export { render, staticRenderFns }