<template>
  <div class=" backdrop">
    <div class="container">
      <div class="flex-col">
        <div class="mb-3 header">
          <h1 class="text-center">Welcome to the AI Video Creator</h1>
          <p :style="{ fontSize: '20px' }" class="text-center">
            Create stunning video content for your campaign in minutes
          </p>
        </div>
        <div class="flex-card">
          <div :style="{ backgroundColor: 'yellow' }">
            <video>
              <source src="/ai-video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div class="overlay">
              <div style="width: 100%;">
                <h3>AI Video Creator</h3>

                <button
                  @click="showPopup('https://www.youtube.com/embed/g94mFXMp1gU')"
                  style="display: flex; align-items: center; justify-content: center; gap: 1rem;"
                  :style="{
                    textDecoration: 'none',
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                  }"
                >
                  <span> Coming Soon</span>

                  <svg width="26" height="26" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect
                      x="0.441176"
                      y="0.941176"
                      width="14.1176"
                      height="14.1176"
                      rx="2.20997"
                      stroke="currentColor"
                      stroke-width="0.882353"
                    />
                    <path
                      d="M4.0909 5.63622C4.0909 4.83048 4.96449 4.32898 5.66064 4.73446L10.3652 7.47941C11.0556 7.88176 11.0556 8.87954 10.3652 9.28241L5.66064 12.0263C4.96449 12.4323 4.0909 11.9308 4.0909 11.1256V5.63622ZM9.83912 8.38065L5.13461 5.63622V11.1256L9.83912 8.38065Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="py-3">
            <div class="video-editor">
              <img :src="require('~/assets/images/aiAutomation.png')" />
              <div :style="{ alignSelf: 'center' }">
                <h2 class="capitalize">Create cool campaign videos using our video composer</h2>
                <p :style="{ maxWidth: '90%', marginTop: '20px', fontSize: '18px' }">
                  Effortlessly design professional-quality campaign videos with our intuitive, feature-rich video
                  composer. Boost engagement, enhance your brand, and captivate your audience today!
                </p>
                <div style="display: flex; gap: 1.5rem">
                  <div>
                    <router-link
                      v-if="!isDisabled"
                      :style="{ textDecoration: 'none' }"
                      class="get-started d-inline-block bg-primary text-white mt-3 py-3 px-5"
                      :to="{ name: 'recent-videos' }"
                    >
                      Get Started
                    </router-link>
                    <button
                      v-else
                      class="get-started d-inline-block bg-primary text-white mt-3 py-3 px-5"
                      disabled
                      :style="{
                        textDecoration: 'none',
                        cursor: isDisabled ? 'not-allowed' : 'pointer',
                      }"
                    >
                      {{ timeRemaining.total <= 0 ? 'Launched!' : ' COMING SOON' }}
                    </button>
                  </div>

                  <button
                    @click="showPopup('https://www.youtube.com/embed/U0HNdsxC8YU')"
                    :style="{ textDecoration: 'none', border: 'none', cursor: isDisabled ? 'not-allowed' : 'pointer' }"
                    class="get-started d-inline-block bg-success  text-white mt-3 py-3 px-5"
                  >
                    WATCH DEMO
                  </button>
                  <DemoVideo
                    v-if="isPopupVisible"
                    :videoUrl="videoUrl"
                    :isVisible="isPopupVisible"
                    @close="closePopup"
                  />
                </div>
                <div style="background: #ffaa00; max-width: 87%; margin-top: 1.5rem;">
                  <div class="text-center text-white fw-bold p-1">
                    <h4>Launching 30th January, 2025</h4>
                    <p>
                      Launch in: {{ timeRemaining.days }} Days, {{ timeRemaining.hours }} Hours,
                      {{ timeRemaining.minutes }} Minutes, {{ timeRemaining.seconds }} Seconds
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DemoVideo from '../VideoComposer/demoVideo.vue';

export default {
  name: 'VideoCreator',
  components: {
    DemoVideo,
  },
  data: () => ({
    isDisabled: true,
    isPopupVisible: false,
    videoUrl: '',
    launchDate: new Date('2025-01-30T00:00:00'),
    timeRemaining: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      total: 0,
    },
  }),
  methods: {
    // watch demo video show popup and close popup functions
    showPopup(url) {
      this.videoUrl = url;
      this.isPopupVisible = true;
    },
    closePopup() {
      this.isPopupVisible = false;
      this.videoUrl = '';
    },

    // launch countdown function
    updateCountdown() {
      const now = new Date();
      const difference = this.launchDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        this.timeRemaining = {
          days,
          hours,
          minutes,
          seconds,
          total: difference,
        };
      } else {
        this.timeRemaining.total = 0;
      }
    },
  },
  mounted() {
    this.updateCountdown();
    setInterval(this.updateCountdown, 1000);
  },
};
</script>
<style lang="scss" scoped>
@import '~@/scss/variables';

$general-padding: 30px;
$card-border-radius: 15px;

.backdrop {
  // position: fixed;
  // top: 0px;
  width: 100%;
  height: 100vh;
  // background-color: $accent-b;
  // background-color: #b6dc0a29;
  padding-top: 100px;
  padding-bottom: 100px;
}
.container {
  max-width: 1500px;
  height: 100%;
  padding-left: $general-padding;
  padding-right: $general-padding;
  margin-left: auto;
  margin-right: auto;
}
.header {
  // background-color: rgba(127, 255, 212, 0.412);
  padding-top: 20px;
  border-radius: 10px;
}
.container > div {
  height: 100%;
}
.container p {
  font-weight: 400;
}
.container h1 {
  font-weight: 800 !important;
  margin-bottom: 0px;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-card {
  height: 100%;
  display: flex;
  gap: 30px;
  transition: all 1s;
}
// .flex-card > div:hover {
//   width: calc(100% * (3 / 4)) !important;
//   transition: all 1s;
// }

.flex-card > div:last-child {
  width: calc(100% * (3 / 4));
  padding-top: 35px;
  padding-bottom: 35px;
  & .video-editor {
    border-radius: $card-border-radius;
    background-color: white;
    padding: 40px;
  }
}
.flex-card > div:first-child {
  position: relative;
  transition: all 1s;
  width: calc(100% * (1 / 4));
  border-radius: $card-border-radius;
}

.flex-card > div:first-child video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: $card-border-radius;
}

.flex-card > div:first-child .overlay {
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  padding: 30px;
  display: flex;
  color: white;
  width: 100%;
  inset: 0;
}

.flex-card > div:first-child .overlay h3 {
  font-weight: 700;
  text-shadow: 0px 0px 1px black;
  text-align: center;
}

.flex-card > div:first-child .overlay button {
  background: linear-gradient(rgb(70, 188, 70), green);
  border-radius: 8px;
  padding: 20px 0px;
  font-weight: 600;
  color: white;
  letter-spacing: 4px;
  text-transform: uppercase;
  width: 100%;
  border: 0px;
}

.get-started {
  border-radius: 8px;
  font-weight: 600;
}

.video-editor {
  display: flex;
  gap: 40px;
  & img {
    object-fit: cover;
    border-radius: 15px;
    // height: 100%;
    // width: auto;
    max-height: 550px;
    width: calc(100% + 200px);
  }
  & h2 {
    font-weight: 600;
  }
}
.dev,
.dev * {
  outline: 1px solid rgba(200, 0, 0, 0.5);
}
</style>
