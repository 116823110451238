<template>
  <div v-if="isVisible" class="popup-overlay" @click="close">
    <div class="popup-content" @click.stop>
      <!-- <button @click="close" class="close-button">×</button> -->
      <iframe style="width: 100%;" :src="videoUrl" frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    videoUrl: {
      type: String,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: white;
  width: 50rem;
  height: 30rem;
  display: flex;
  /* padding: 20px; */
  border-radius: 5px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 0px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 40px;
}
</style>
